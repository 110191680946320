@import "~datatables.net-dt/css/jquery.dataTables.min.css";
@import "~datatables.net-responsive-dt/css/responsive.dataTables.css";
@import "~materialize-css/dist/css/materialize.css";


*{
    box-sizing: border-box;
    margin:0px;
    padding:0px;
}

.form a {
    text-decoration: none;
    color: #fff;
    transition: .5s ease;
}
.form a:hover {
    color: #179b77;
}

.form {
    padding: 40px;
    max-width: 600px;
    margin: 40px auto;
    border-radius: 4px;
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4));
    box-shadow: 0 4px 10px 4px rgba(19, 35, 47, 0.3);
    transition: .5s ease;
}

.form:hover {
    box-shadow: 0px 0px 40px 16px rgba(18,18,18,1.00);
}

.tab-group {
    list-style: none;
    padding: 0;
    margin: 0 0 40px 0;
}
.tab-group:after {
    content: "";
    display: table;
    clear: both;
}
.tab-group li a {
    display: block;
    text-decoration: none;
    padding: 15px;
    background: #000;
    color: #fff;
    font-size: 20px;
    float: left;
    width: 50%;
    text-align: center;
    cursor: pointer;
    transition: .5s ease;
}
.tab-group li a:hover {
    background: #179b77;
    color: #ffffff;
}
.tab-group .active a {
    background: #1ab188;
    color: #ffffff;
}

.tab-content > div:last-child {
    display: none;
}

.form .title {
    text-align: center;
    color: #fff;
    font-weight: 300;
    margin: 0 0 40px;
}

.form label {
    position: absolute;
    transform: translateY(6px);
    left: 13px;
    color: rgba(255,255,255,0.7);
    transition: all 0.25s ease;
    pointer-events: none;
    font-size: 22px;
}
.form label .req, .req {
    margin: 2px;
    color: red;
}

.form label.active {
    transform: translateY(50px);
    left: 2px;
    font-size: 14px;
}
.form label.active .req {
    opacity: 0;
}

.form label.highlight {
    color: #000;
    margin-top:-10px;
}

.form input {
    font-size: 22px;
    display: block;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    background: none;
    background-image: none;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 0;
    transition: border-color .25s ease, box-shadow .25s ease;
}
.form input:focus{
    outline: 0;
    border-color: #000;
}

.field-wrap {
    position: relative;
    margin-bottom: 40px;
}

.top-row:after {
    content: "";
    display: table;
    clear: both;
}
.top-row > div {
    float: left;
    width: 48%;
    margin-right: 4%;
}
.top-row > div:last-child {
    margin: 0;
}

.button {
    border: 0;
    outline: none;
    border-radius: 0;
    /*padding: 15px 0;*/
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .1em;
    background: #95b607;
    color: #ffffff;
    transition: all 0.5s ease;
}
.button:hover, .button:focus {
    background: #95b607;
    cursor: pointer;
}

.button-block {
    display: block;
    width: 100%;
}

.forgot {
    margin-top: -20px;
    text-align: right;
    margin-bottom:10px;
}

.sidenav li .side-menu{
    color: #444444;
    border-bottom: 1px solid #111;
    display: block;
    padding: 10px ;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold
}
.sidenav li a:hover{
    color: #0f9d58;
}

.message-info-tarif{
    color: gray;
    font-size: 0.7em;
}

div[class$="choice"] .label-choice{
    font-size: 0.7em;
    color: #636e72;
}

table tr {border: none;}

fieldset legend{
    font-weight: bold;
    font-size:large;
    font-style: oblique;
    padding: 0 4px 2px 4px;
}

td.accent{  font-style: oblique;
    font-weight: bold;

}

.green-camping {background-color:#95b607;}
.btn.green-camping:hover {background-color:#e3521d;}
.green-camping-text {color:#95b607;}
i.green-camping-text:hover {color:#e3521d;}

.btn.btn-background {background-color:#95b607; font-weight: bold}
.btn.btn-background:hover {background-color:#e3521d;}
i.btn-background-text {color:#95b607;}
i.btn-background-text:hover {color:#e3521d;}

div.relative{
    position: relative;
}
a.edit-filed{
    position: absolute;
    top: 0px;
    right: 10px;
}

/*.button, .btn{background-color:#95b607;}*/
/*.color{background-color:#95b607;}*/
/*.btn:hover, .button:hover{background-color:#e3521d;}*/

/*.btn.color:hover, .button.color:hover{background-color:#e3521d;}*/

/*fieldset { background-color: #F5FBEF;}*/