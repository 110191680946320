/*---------------------------------------------------
    LESS Elements 0.9
  ---------------------------------------------------
    A set of useful LESS mixins
    More info at: http://lesselements.com
  ---------------------------------------------------*/
/* Theme Variables
------------------------------------------*/
.note-editor .modal .modal-footer .modal-close {
  background-color: #c62828;
  color: #eeeeee;
}

.note-editor .modal .modal-footer .note-image-btn, .note-editor .modal .modal-footer .note-link-btn, .note-editor .modal .modal-footer .note-video-btn, .note-editor .modal .modal-footer .note-materialize-card-btn {
  background-color: #2e7d32;
  color: #eeeeee;
}

.note-editor .modal .file-uploader-wrapper {
  background-color: #3d5afe;
  color: #eeeeee;
}

.note-editor .disabled {
  background-color: #555555 !important;
  color: #bbbbbb !important;
}

.note-editor .modal-content::-webkit-scrollbar, .note-editor .dropdown-content::-webkit-scrollbar, .note-editor .note-editing-area .note-editable::-webkit-scrollbar {
  width: 17px;
}
.note-editor .modal-content::-webkit-scrollbar-button, .note-editor .dropdown-content::-webkit-scrollbar-button, .note-editor .note-editing-area .note-editable::-webkit-scrollbar-button {
  display: none !important;
}
.note-editor .modal-content::-webkit-scrollbar-track, .note-editor .dropdown-content::-webkit-scrollbar-track, .note-editor .note-editing-area .note-editable::-webkit-scrollbar-track {
  background-color: #212121;
}
.note-editor .modal-content::-webkit-scrollbar-thumb, .note-editor .dropdown-content::-webkit-scrollbar-thumb, .note-editor .note-editing-area .note-editable::-webkit-scrollbar-thumb {
  background-color: #454545;
}
.note-editor .modal-content::-webkit-scrollbar-thumb:hover, .note-editor .dropdown-content::-webkit-scrollbar-thumb:hover, .note-editor .note-editing-area .note-editable::-webkit-scrollbar-thumb:hover {
  background-color: #535353;
}

.note-editor .modal-footer a:link {
  color: #ffc107;
}
.note-editor .modal-footer a:hover {
  color: #ffab00;
}
.note-editor .modal-footer a:visited {
  color: #ff6d00;
}
.note-editor .modal-footer a:active {
  color: #ffab00;
}

/* Layout
------------------------------------------*/
.note-editor .note-color-palette, #note-card-modal .note-color-palette {
  line-height: 1;
}
.note-editor .note-color-palette .note-color-row, #note-card-modal .note-color-palette .note-color-row {
  line-height: 0;
}
.note-editor .note-color-palette div .note-color-btn, #note-card-modal .note-color-palette div .note-color-btn {
  width: 23px;
  height: 23px;
  padding: 0;
  margin: 0;
  border: none;
}
.note-editor .note-color-palette div .note-color-btn:hover::after, #note-card-modal .note-color-palette div .note-color-btn:hover::after {
  position: absolute;
  width: 34px;
  height: 34px;
  content: "";
  background-color: inherit;
  margin-top: -17px;
  margin-left: -17px;
  border: solid 2px #212121;
}
.note-editor .note-color .note-current-color-button, #note-card-modal .note-color .note-current-color-button {
  width: 36px;
}
.note-editor .note-color .note-current-color-button.disabled .note-recent-color, .note-editor .note-color .note-current-color-button.disabled .note-recent-color-back, #note-card-modal .note-color .note-current-color-button.disabled .note-recent-color, #note-card-modal .note-color .note-current-color-button.disabled .note-recent-color-back {
  background-color: #555555 !important;
  color: #bbbbbb !important;
}
.note-editor .note-color .note-current-color-button .note-recent-color, #note-card-modal .note-color .note-current-color-button .note-recent-color {
  z-index: 2;
  font-weight: 600;
  line-height: 2.2;
  position: absolute;
  left: 13px;
}
.note-editor .note-color .note-current-color-button .note-recent-color-back, #note-card-modal .note-color .note-current-color-button .note-recent-color-back {
  z-index: 1;
  height: 20px;
  width: 19.5px;
  background-color: #eeeeee;
  position: absolute;
  bottom: 10px;
}

#note-card-modal {
  top: 5% !important;
  height: 90%;
  max-height: 90%;
}

.note-editor {
  position: relative;
  /* dropzone */
  /* Modals
  ------------------------------------------*/
}
.note-editor .modal {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-width: 0;
}
.note-editor .modal-content {
  border-color: #212121 !important;
}
.note-editor .modal-content .card-color-wrapper {
  max-width: 420px;
  margin: auto;
}
.note-editor .modal-content .card-color-wrapper .tab {
  background-color: #323232;
}
.note-editor .modal-content .card-color-wrapper .tab:hover {
  background-color: #3d5afe !important;
  color: #eeeeee !important;
}
.note-editor .modal-content .selected-color {
  background-color: #323232;
  height: 40px;
  width: 40px;
  margin: auto;
  border: solid 2px #4c4c4c;
}
.note-editor .modal-content .help-content .help-container {
  margin-left: 5%;
  margin-right: 5%;
}
.note-editor .modal-content .help-content .note-help-row {
  margin-bottom: 3px;
  min-height: 36px;
  padding-top: 6px;
}
.note-editor .modal-content .help-content .note-help-row:nth-child(even) {
  background-color: #3b3b3b;
}
.note-editor .modal-content .help-content .note-help-row:hover {
  background-color: #3d5afe;
}
.note-editor .modal-content .help-content .note-help-row:hover kbd {
  background-color: #ffc107;
  color: #212121;
}
.note-editor .modal-content .help-content label {
  position: relative;
  top: 0;
}
.note-editor .modal-content .help-content label kbd {
  background-color: #3d5afe;
  color: #eeeeee;
  padding: 4px 5px 2px 5px;
}
.note-editor .dropdown-content, .note-editor .modal-content {
  min-width: 10px;
  background-color: #212121;
  border-left: solid 1px #eeeeee;
  border-right: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
  color: #eeeeee;
  /* dropdown-menu right position */
  /* http://forrst.com/posts/Bootstrap_right_positioned_dropdown-2KB */
  /* dropdown-menu for selectbox */
}
.note-editor .dropdown-content .tabs, .note-editor .modal-content .tabs {
  background-color: #212121;
}
.note-editor .dropdown-content .tabs .tab.col, .note-editor .modal-content .tabs .tab.col {
  float: none;
}
.note-editor .dropdown-content .tabs .tab.col:hover, .note-editor .modal-content .tabs .tab.col:hover {
  background-color: #212121;
}
.note-editor .dropdown-content .tabs .tab.col a, .note-editor .modal-content .tabs .tab.col a {
  line-height: 48px;
  color: #eeeeee;
}
.note-editor .dropdown-content .tabs .indicator, .note-editor .modal-content .tabs .indicator {
  height: 5px;
  min-height: 0;
  background-color: #3d5afe;
  width: 50%;
}
.note-editor .dropdown-content .row.noMargins, .note-editor .modal-content .row.noMargins {
  margin-left: auto;
  margin-right: auto;
}
.note-editor .dropdown-content .note-color-reset.btn, .note-editor .modal-content .note-color-reset.btn {
  background-color: #323232;
}
.note-editor .dropdown-content .note-color-reset.btn:hover, .note-editor .modal-content .note-color-reset.btn:hover {
  background-color: #3d5afe;
  color: #eeeeee;
}
.note-editor .dropdown-content .color-name, .note-editor .modal-content .color-name {
  color: #ffc107;
  line-height: 36px;
  height: 36px;
  opacity: 0;
}
.note-editor .dropdown-content.right, .note-editor .modal-content.right {
  right: 0;
  left: auto;
}
.note-editor .dropdown-content.right::before, .note-editor .modal-content.right::before {
  right: 9px;
  left: auto !important;
}
.note-editor .dropdown-content.right::after, .note-editor .modal-content.right::after {
  right: 10px;
  left: auto !important;
}
.note-editor .dropdown-content.note-check li, .note-editor .modal-content.note-check li {
  list-style: none;
}
.note-editor .dropdown-content.note-check li:hover, .note-editor .modal-content.note-check li:hover {
  background-color: #3d5afe;
  color: #eeeeee;
}
.note-editor .dropdown-content.note-check li a, .note-editor .modal-content.note-check li a {
  color: #eeeeee;
}
.note-editor .dropdown-content.note-check li a i, .note-editor .modal-content.note-check li a i {
  color: #eeeeee !important;
  visibility: hidden;
}
.note-editor .dropdown-content.note-check li a.checked i, .note-editor .modal-content.note-check li a.checked i {
  visibility: visible;
}
.note-editor .note-dropzone {
  position: absolute;
  display: none;
  z-index: 100;
  color: lightskyblue;
  background-color: white;
  opacity: 0.95;
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.note-editor .note-dropzone.hover {
  color: #098ddf;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor .note-editing-area {
  position: relative;
}
.note-editor .note-editing-area .note-editable {
  outline: none;
}
.note-editor .note-editing-area .note-editable sup {
  vertical-align: super;
}
.note-editor .note-editing-area .note-editable sub {
  vertical-align: sub;
}
.note-editor .modal {
  background-color: #212121;
  color: #eeeeee;
  /*& > div {
      display: none; // BS2's hide pacth.
  }*/
}
.note-editor .modal .form-group {
  /* overwrite BS's form-horizontal minus margins */
  margin-left: 0;
  margin-right: 0;
}
.note-editor .modal .note-modal-form {
  margin: 0;
  /* overwrite BS2's form margin bottom */
}
.note-editor .modal .note-image-dialog .note-dropzone {
  min-height: 100px;
  font-size: 30px;
  line-height: 4;
  /* vertical-align */
  color: lightgray;
  text-align: center;
  border: 4px dashed lightgray;
  margin-bottom: 10px;
}
.note-editor .modal .modal-footer {
  background-color: #212121;
}
.note-editor .modal .modal-footer .modal-close {
  margin-right: 7px;
}
.note-editor input:not([type]):focus:not([readonly]) + label,
.note-editor input[type=text]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=password]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=email]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=url]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=time]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=date]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=number]:not(.browser-default):focus:not([readonly]) + label,
.note-editor input[type=search]:not(.browser-default):focus:not([readonly]) + label,
.note-editor textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #ffc107;
}
.note-editor input:not([type]):focus:not([readonly]),
.note-editor input[type=text]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=password]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=email]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=url]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=time]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=date]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=datetime]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=tel]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=number]:not(.browser-default):focus:not([readonly]),
.note-editor input[type=search]:not(.browser-default):focus:not([readonly]),
.note-editor textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #ffc107;
  -webkit-box-shadow: 0 1px 0 0 #ffc107;
  box-shadow: 0 1px 0 0 #ffc107;
}
.note-editor [type="checkbox"]:checked + label:before {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #ffc107;
  border-bottom: 2px solid #ffc107;
}

/* Frame mode layout
------------------------------------------*/
.note-editor.note-frame {
  border-left: 3px solid #212121;
  border-right: 3px solid #212121;
  /* codeview mode */
  /* fullscreen mode */
  /* statusbar */
}
.note-editor.note-frame.codeview .note-editing-area .note-editable {
  display: none;
}
.note-editor.note-frame.codeview .note-editing-area .note-codable {
  display: block;
}
.note-editor.note-frame .note-editing-area {
  overflow: hidden;
  /* editable */
  /* codable */
}
.note-editor.note-frame .note-editing-area .note-editable {
  background-color: #fff;
  color: #000;
  padding: 10px;
  overflow: auto;
}
.note-editor.note-frame .note-editing-area .note-editable[contenteditable="false"] {
  background-color: #e5e5e5;
}
.note-editor.note-frame .note-editing-area .note-codable {
  display: none;
  width: 100%;
  padding: 10px;
  border: none;
  box-shadow: none;
  font-family: roboto;
  font-size: 14px;
  color: #ffc107;
  background-color: #212121;
  resize: none;
  outline: none;
  /* override BS2 default style */
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin-bottom: 0;
}
.note-editor.note-frame.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: 1050;
  /* materialize modal-backdrop: 1030, bs2: 1040 */
}
.note-editor.note-frame.fullscreen .note-editable {
  background-color: white;
}
.note-editor.note-frame.fullscreen .note-resizebar {
  display: none;
}
.note-editor.note-frame .note-statusbar {
  background-color: #212121;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.note-editor.note-frame .note-statusbar .note-resizebar {
  padding-top: 1px;
  height: 17px;
  width: 100%;
  cursor: ns-resize;
}
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 2px auto;
  border-top: 2px solid #eeeeee;
}
.note-editor.note-frame .note-placeholder {
  padding: 10px;
}

/* Popover
------------------------------------------*/
.note-popover.popover {
  max-width: none;
  display: none;
  position: absolute;
  margin-top: 3px;
}
.note-popover.popover .popover-content {
  background-color: #212121;
}
.note-popover.popover .popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* for FF */
  vertical-align: middle;
  /* for FF */
}
.note-popover.popover .popover-link {
  margin-left: 7px;
}
.note-popover.popover .popover-link a:link, .note-popover.popover .popover-link a:visited {
  color: #3d5afe;
}
.note-popover.popover .popover-link a:hover, .note-popover.popover .popover-link a:active {
  color: #ffc107;
}
.note-popover.popover .arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 11px 11px 11px;
  border-color: transparent transparent #212121 transparent;
  margin-left: 7px;
}

/* Popover and Toolbar (Button container)
------------------------------------------*/
.note-toolbar {
  z-index: 500;
  background-color: #212121;
  position: relative;
  display: table-row;
}

.note-popover .popover-content, .panel-heading.note-toolbar {
  margin: 0;
  padding: 0;
}
.note-popover .popover-content > .btn-group, .panel-heading.note-toolbar > .btn-group {
  margin-top: 0;
  margin-left: 0;
  margin-right: 5px;
}
.note-popover .popover-content .btn-group, .panel-heading.note-toolbar .btn-group {
  display: inline-block;
}
.note-popover .popover-content .btn-group .note-table, .panel-heading.note-toolbar .btn-group .note-table {
  width: 300px;
  padding: 5px;
  overflow: visible;
}
.note-popover .popover-content .btn-group .note-table .beforePicker, .panel-heading.note-toolbar .btn-group .note-table .beforePicker {
  margin-left: 4px;
  margin-right: 4px;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker {
  font-size: 18px;
  margin-left: 14px;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 260px;
  height: 260px;
  cursor: pointer;
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 260px;
  height: 130px;
  background-size: 26px 26px;
  background-image: repeating-linear-gradient(0deg, #212121, #212121 4px, transparent 4px, transparent 26px), repeating-linear-gradient(-90deg, transparent, transparent 4px, #fff 4px, #fff 26px);
}
.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 26px;
  height: 26px;
  background-size: 26px 26px;
  background-image: repeating-linear-gradient(0deg, #212121, #212121 4px, transparent 4px, transparent 26px), repeating-linear-gradient(-90deg, transparent, transparent 4px, #3d5afe 4px, #3d5afe 26px);
}
.note-popover .popover-content .btn-group .note-table .note-dimension-display, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-display {
  margin-left: 14px;
}
.note-popover .popover-content .btn, .panel-heading.note-toolbar .btn {
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 9px !important;
  background-color: #212121;
}
.note-popover .popover-content .btn:hover, .panel-heading.note-toolbar .btn:hover {
  background-color: #323232;
}
.note-popover .popover-content .btn.active, .panel-heading.note-toolbar .btn.active {
  background-color: #3d5afe;
  color: #eeeeee;
}
.note-popover .popover-content .note-style h1, .note-popover .popover-content .note-style h2, .note-popover .popover-content .note-style h3, .note-popover .popover-content .note-style h4, .note-popover .popover-content .note-style h5, .note-popover .popover-content .note-style h6, .note-popover .popover-content .note-style blockquote, .panel-heading.note-toolbar .note-style h1, .panel-heading.note-toolbar .note-style h2, .panel-heading.note-toolbar .note-style h3, .panel-heading.note-toolbar .note-style h4, .panel-heading.note-toolbar .note-style h5, .panel-heading.note-toolbar .note-style h6, .panel-heading.note-toolbar .note-style blockquote {
  margin: 0;
}
.note-popover .popover-content .note-para .dropdown-menu, .panel-heading.note-toolbar .note-para .dropdown-menu {
  min-width: 216px;
  padding: 5px;
}
.note-popover .popover-content .note-para .dropdown-menu > div:first-child, .panel-heading.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 5px;
}
.note-popover .popover-content .note-fontsize-10, .panel-heading.note-toolbar .note-fontsize-10 {
  font-size: 10px;
}

/* Placeholder
------------------------------------------*/
.note-placeholder {
  position: absolute;
  display: none;
  color: gray;
}

/* Handle
------------------------------------------*/
.note-handle {
  /* control selection */
}
.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 3px solid #212121;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: #212121;
  -webkit-opacity: 0.3;
  -khtml-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
  filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle, .note-handle .note-control-selection .note-control-holder, .note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  border: 1px solid black;
}
.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #212121 transparent transparent transparent;
}
.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #212121 transparent transparent;
}
.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 0 15px;
  border-color: transparent transparent transparent #212121;
}
.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 15px 15px;
  border-color: transparent transparent #212121 transparent;
  cursor: se-resize;
  transition: all 0.5s;
}
.note-handle .note-control-selection .note-control-se:hover {
  right: -5px;
  bottom: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 25px 25px;
  border-color: transparent transparent #212121 transparent;
  border-color: transparent transparent #3d5afe transparent;
}
.note-handle .note-control-selection .note-control-se.note-control-holder {
  cursor: default;
  border-top: none;
  border-left: none;
}
.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  color: #eeeeee;
  background-color: #212121;
  font-size: 12px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-opacity: 0.7;
  -khtml-opacity: 0.7;
  -moz-opacity: 0.7;
  opacity: 0.7;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=70);
  filter: alpha(opacity=70);
}

.note-hint-popover {
  min-width: 100px;
  padding: 2px;
}
.note-hint-popover .popover-content {
  padding: 3px;
  max-height: 150px;
  overflow: auto;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item {
  display: block !important;
  padding: 3px;
}
.note-hint-popover .popover-content .note-hint-group .note-hint-item.active, .note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.4;
  color: white;
  white-space: nowrap;
  text-decoration: none;
  background-color: #428bca;
  outline: 0;
  cursor: pointer;
}

/*# sourceMappingURL=materialnote.css.map */
